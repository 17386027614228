document.addEventListener("DOMContentLoaded", function() {
    var script1 = document.createElement('script');
    script1.src = "//a.insiteful.co/dist/compile.min.js";
    script1.onload = function() {
        insiteful_activate('NjY1Nzc3ZTg0YzllNg==');
    };
    document.body.appendChild(script1);

    var script2 = document.createElement('script');
    script2.src = "//a.insiteful.co/dist/track-heyflow-mc.min.js";
    script2.async = true;
    script2.defer = true;
    script2.id = 'insiteful-heyflow-js';
    document.body.appendChild(script2);
});
window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});